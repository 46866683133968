import HeaderContainer from './HeaderContainer';
import DetailPageHeader from './DetailPageHeader';
import HeaderInfoAuthorized from './HeaderInfoAuthorized';

export default {
  USE_SIGN_UP_MODAL: true,
  SORT_RACING_ODDS_MOBILE: true,
  EXPANDED_SELECTION: true,
  MIX_PROMOTIONS_BONUSES: true,
  MAX_MARKET_ROWS_COUNT: 5,
  animations: {
    bonus: true,
  },
  HeaderContainer,
  DetailPageHeader,
  HeaderInfoAuthorized,
  // PaymentMethodList,
};
