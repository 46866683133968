import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { map } from 'lodash';
import {
  Bus,
  constants,
  getAvailablePages,
  SettingsManager,
  SiteConfigManager,
  useApplicationState,
  useSettings,
} from '@apollo/core';
import { accountRoutes, COMPONENT_TYPES } from '@apollo/routing';
import cx from 'classnames';
import { CLIENT_PROFILE_COMPONENT_TYPES } from '@apollo/routing/src/routes/componentTypes';
import { clientProfileRoutes } from '@apollo/routing/src/routes/accountRoutes';
import HeaderInfoAuthorizedBalance from '../../../src/app/shared/components/Header/HeaderStatusBlock/HeaderInfoAuthorizedBalance';
import useTranslate from '../../../src/app/shared/components/I18n/Interpreter';
import Label from '../../../src/app/shared/components/I18n/Label';
import Dropdown from '../../../src/app/shared/components/Dropdown/Dropdown';
import { isDateFuture } from '../../../src/app/features/ClientProfile/Bonuses/BonusesTable/bonusesUtils';
import { AnimatedDropdown } from '../../../src/app/features/Animations/AnimatedDropdown';
import { isPromotionsActive } from '../../../src/app/core/utils';
import { BonusSelector } from '../../../src/app/state/Bonus/Bonus';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const onLogOut = () => {
  Bus.emit(Bus.events.auth.logout);
};

const bonusTabs = [
  // constants.BONUS_TYPE.FREEBET_DEPOSIT,
  constants.BONUS_TYPE.ODDS_BOOST,
  constants.BONUS_TYPE.FREEBET,
  constants.BONUS_TYPE.BET_RETURN,
];

const HeaderInfoAuthorized = ({ expanded }) => {
  HeaderInfoAuthorized.displayName = '123Bet.HeaderInfoAuthorized';
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);
  const { payment } = clientProfileRoutes;
  const { layout, account } = useApplicationState();
  const toggleRef = useRef(null);

  const bonuses = BonusSelector.selectAllBonuses();

  const isVerify = account?.clientVerificationState?.toLowerCase() === 'verified';

  const countBonuses = bonuses.filter(
    (b) => isDateFuture(b.props?.expiry) || b.bonusType === constants.BONUS_TYPE.FREEBET_DEPOSIT,
  ).length;

  // const [bonusState] = useBonusState();
  // console.log('BONUS STUFF', bonusState);

  const hasActiveDepositBonus = BonusSelector.hasActiveDepositBonus();

  const t = useTranslate();

  const { isBalanceHidden } = useSettings();

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const availableClientProfileRoutes = {
    [CLIENT_PROFILE_COMPONENT_TYPES.BONUSES]: {
      name: 'Bonuses',
      icon: 'bonus-icon',
      link: clientProfileRoutes.bonuses,
    },
    [CLIENT_PROFILE_COMPONENT_TYPES.HISTORY]: {
      name: 'History',
      icon: 'calendar-icon',
      link: clientProfileRoutes.history,
    },
    [CLIENT_PROFILE_COMPONENT_TYPES.PROFILE]: {
      name: 'client_profile',
      icon: 'user-badge-icon',
      link: clientProfileRoutes.profile,
    },
    [CLIENT_PROFILE_COMPONENT_TYPES.RESPONSIBLE_GAMING]: {
      name: 'Responsible Gaming',
      icon: 'self-regulation-icon',
      link: clientProfileRoutes.responsibleGaming,
    },
    [CLIENT_PROFILE_COMPONENT_TYPES.COMMUNICATION]: {
      name: 'Communication',
      icon: 'send-to-mail-icon',
      link: clientProfileRoutes.communication,
    },
    [CLIENT_PROFILE_COMPONENT_TYPES.SETTINGS]: {
      name: 'Betslip Settings',
      icon: 'settings-gear-icon',
      link: clientProfileRoutes.settings,
    },
  };

  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs],
  );

  const promotionsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS);
  const promotionsActive = useMemo(() => isPromotionsActive(), [promotionsRoute]);

  const bonusLink = useMemo(() => tabs?.find((t) => t.type === 'bonuses')?.link || '', [tabs]);

  const [isExpanded, setExpanded] = useState(false);

  const onClickHandler = useCallback(() => {
    setExpanded(false);
    if (layout.mobileDevice) {
      Bus.send({
        event: Bus.events.layout.setMainMenu,
        data: false,
      });
    }
  }, [layout]);

  const toggleHideBalance = useCallback(() => {
    SettingsManager.updateSettings({
      isBalanceHidden: !isBalanceHidden,
    });
  }, [isBalanceHidden]);

  const timeoutId = useRef(null);
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);

  const bonusActivateHandler = () => {
    clearTimeout(timeoutId.current);
    setIsAnimationEnabled(true);
    timeoutId.current = setTimeout(() => setIsAnimationEnabled(false), 5000);
  };

  useEffect(() => {
    Bus.on(Bus.events.profile.bonusActivate, bonusActivateHandler);
    return () => {
      Bus.off(Bus.events.profile.bonusActivate, bonusActivateHandler);
    };
  }, []);

  if (expanded) {
    return (
      <div className='header__menu__items'>
        <div className='header__menu__title'>
          <Label message='My Account' />
        </div>
        {map(tabs, ({ name, link }) => (
          <NavLink
            key={link}
            to={`${clientProfilePath}/${link}`}
            className='header__menu__item header__menu__item--sub'
            activeClassName='active'
            onClick={onClickHandler}
          >
            <Label message={name} />
          </NavLink>
        ))}

        <div onClick={onLogOut} className='header__menu__item header__menu__item--sub text-danger'>
          <Label message='logout' />
        </div>
        {!isBalanceHidden && <HeaderInfoAuthorizedBalance />}
      </div>
    );
  }

  return (
    <div className='btn-group btn-group--auth'>
      {bonuses.length > 0 && (
        <div
          className={`header__account__bet-bonus ${isAnimationEnabled ? 'header__account__bet-bonus--animated' : ''}`}
        >
          <span className='header__account__bet-bonus--counter'>{countBonuses}</span>

          <NavLink
            to={`${clientProfilePath}/${bonusLink}`}
            className='header__account__bet-bonus--icon'
          >
            <i className='icons icon--bonus_account_info' />
          </NavLink>
        </div>
      )}
      <Dropdown onExpanded={setExpanded} toggleRef={toggleRef} className='header__account'>
        <div ref={toggleRef} className='header__account__btn'>
          {!isBalanceHidden && <HeaderInfoAuthorizedBalance />}
          <div className='account__current'>
            <div
              className={cx('account_dropdown__info__verification', {
                account__verified: account?.clientVerificationState?.toLowerCase() === 'verified',
              })}
            />
            <span className='account__current__arrow AkIcon-errow-icon' />
          </div>
        </div>

        <AnimatedDropdown
          isExpanded={isExpanded}
          className={cx('account_dropdown', {
            'account_dropdown--mobile': layout.mobileDevice,
          })}
          endOverflow='auto'
        >
          <div className='account_dropdown__info'>
            <div className='client_info'>
              <div className='client_info__picture' />
              <div className='client_info__block'>
                <span className='client_info__block__name'>
                  <span className='client_info__block__name--first-name'>{account?.firstName}</span>
                  <span className='client_info__block__name--last-name'>{account?.lastName}</span>
                </span>
                <span className='client_info__block__id'>
                  <Label className='client-info__label text-nowrap' message='User ID' />
                  :&nbsp;
                  {account?.id}
                </span>
              </div>
            </div>

            <div className='account_dropdown__client-status'>
              {isVerify ? (
                <div
                  className={cx('account_dropdown__info-inner__verification', {
                    account__verified:
                      account?.clientVerificationState?.toLowerCase() === 'verified',
                  })}
                >
                  <span className='account_dropdown__verification__icon' />
                </div>
              ) : (
                <NavLink
                  to={`${clientProfilePath}/${availableClientProfileRoutes[CLIENT_PROFILE_COMPONENT_TYPES.PROFILE]?.link}/${accountRoutes.verifyDocuments}`}
                  className={cx('account_dropdown__info-inner__verification', {
                    account__verified:
                      account?.clientVerificationState?.toLowerCase() === 'verified',
                  })}
                  activeClassName='active'
                  onClick={onClickHandler}
                >
                  <Label message={account?.clientVerificationState?.toLowerCase()} />
                </NavLink>
              )}

              <div
                className={cx('account_dropdown__status__verification', {
                  account__verified: account?.clientVerificationState?.toLowerCase() === 'verified',
                })}
              >
                {!isVerify ? (
                  <Label message='unverified_status' />
                ) : (
                  <Label message={account?.clientVerificationState?.toLowerCase()} />
                )}
              </div>
            </div>
          </div>
          {bonuses.length > 0 ? (
            <>
              <div className='account_dropdown__bonuses'>
                {bonusTabs?.map((tab) => {
                  const depositLink = tabs.find((t) => t.name === 'deposit_withdraw')?.link;
                  const link = tab === constants.BONUS_TYPE.FREEBET_DEPOSIT
                    ? `${clientProfilePath}/${depositLink}`
                    : `${clientProfilePath}/${bonusLink}/${tab.toLowerCase()}`;
                  return (
                    <NavLink
                      key={tab}
                      className='bet-bonus--link'
                      to={link}
                      onClick={() => setExpanded(false)}
                    >
                      <div className='bet-bonus--icon'>
                        <div className='bet-bonus--icon--wrapper'>
                          <i className={`icons icon--${tab.toLowerCase()}-black`} title={t(tab)} />
                        </div>
                        <span className='bet-bonus--counter'>
                          {
                            bonuses.filter(
                              (b) => b.bonusType === tab
                                && (isDateFuture(b.props?.expiry)
                                  || b.bonusType === constants.BONUS_TYPE.FREEBET_DEPOSIT),
                            ).length
                          }
                        </span>
                      </div>
                      <Label message={tab} />
                    </NavLink>
                  );
                })}
              </div>
              <div className='account_dropdown__bonuses account_dropdown__bonuses--all'>
                <div className='bet-bonus--animation' />

                <NavLink
                  className='bet-bonus--link--all'
                  to={`${clientProfilePath}/${bonusLink}`}
                  onClick={() => setExpanded(false)}
                >
                  <i className='icons icon--bet-bonus-all' />
                  <Label message='Bonuses' />
                </NavLink>
              </div>
            </>
          ) : null}

          <div className='account_dropdown__balance'>
            <div
              className={cx('account_dropdown__balance__item', {
                balance__hidden: isBalanceHidden,
              })}
            >
              <div className='account_dropdown__balance__name'>
                <Label message='balance' />
                <i
                  className={`account_dropdown__balance__icon ${isBalanceHidden ? 'ui__eye-icon' : 'ui__eye-icon _visible'}`}
                  onClick={toggleHideBalance}
                />
              </div>
              <div className='account_dropdown__balance__value'>
                {!isBalanceHidden ? <HeaderInfoAuthorizedBalance /> : '***'}
              </div>
            </div>
            <div className='account_dropdown__balance__item'>
              <div className='account_dropdown__balance__name'>
                <Label message='bonus' />
              </div>
              <div className='account_dropdown__balance__value'>{countBonuses}</div>
            </div>
          </div>
          <div className='account_dropdown__payments'>
            <NavLink
              className='bBasePrimary'
              to={`${clientProfilePath}/${payment}/${CLIENT_PROFILE_COMPONENT_TYPES.PAYMENT_DEPOSIT}`}
              onClick={() => setExpanded(false)}
            >
              <Label message='deposit' />
              {hasActiveDepositBonus ? (
                <i className='icons icon--bonus_account_info' title='Bonus Bet' />
              ) : null}
            </NavLink>
            <NavLink
              className='bBasePrimary bBasePrimary_outline'
              to={`${clientProfilePath}/${payment}/${CLIENT_PROFILE_COMPONENT_TYPES.PAYMENT_WITHDRAWAL}`}
              onClick={() => setExpanded(false)}
            >
              <Label message='withdrawal' />
            </NavLink>
          </div>

          <div className='account_dropdown__links'>
            {map(tabs, ({ name, link, icon }) => (
              <NavLink
                key={link}
                to={`${clientProfilePath}/${link}`}
                activeClassName='active'
                className='account-link'
                onClick={() => setExpanded(false)}
              >
                <i className={`account_dropdown__links__icon AkIcon-${icon}`} title={name} />
                <Label message={name} />
              </NavLink>
            ))}
          </div>

          <div className='account_dropdown__footer'>
            <div onClick={() => setExpanded(false)} className='account_dropdown__close-button'>
              <span className='account_dropdown__logout-link__icon AkrIcon-close' />
              <Label message='close' />
            </div>

            <a
              className='account_dropdown__logout-link'
              onClick={() => {
                setExpanded(false);
                onLogOut();
              }}
            >
              <Label message='logout' />
              <span className='account_dropdown__logout-link__icon AkrIcon-exit' />
            </a>
          </div>
        </AnimatedDropdown>
      </Dropdown>
    </div>
  );
};

export default HeaderInfoAuthorized;
