import React from 'react';
import { useApplicationState } from '@apollo/core';
import Header from '../../../src/app/shared/components/Header/Header';
import NextToJumpBar from '../../../src/app/shared/components/Header/NextToJumpBar/NextToJumpBar';
import BetSlipButton from '../../../src/app/shared/components/Header/HeaderStatusBlock/BetSlipButton/BetSlipButton';

const HeaderContainer = () => {
  const { layout } = useApplicationState();
  return (
    <>
      <Header />
      {layout && layout.mobileDevice === false ? (
        <div className='next-jump-bar__container'>
          <NextToJumpBar />
          <BetSlipButton />
        </div>
      ) : null}
    </>
  );
};
export default HeaderContainer;
